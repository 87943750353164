require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {


    // TODO: 
    // - make function to generate and append the news feeds



    // - make function to request new feeds from the API

    

    // Sample
    // let page = 0;
    // const { url: apiUrl } = JSON.parse(document.getElementById('api-url').textContent);
    // const response = await fetch(apiUrl + '/feed?page=' + page);
    // const feeds = await response.json();
    // console.log('FEEDS GENERATED', feeds);
    // Sample END


    // TODO: 
    // - make function to generate and append the news feeds
    let page = 0;

    async function generateFeed(page) {
        let numPages = 6;
        const { url: apiUrl } = JSON.parse(document.getElementById('api-url').textContent);
        const response = await fetch(apiUrl + '/feed?page=' + page);
        const feeds = await response.json();

        const newsWrapper = document.querySelector('.news-wrapper');

        for (let i = 0; i < numPages; i++) {
            const newsBox = document.createElement('a');
            let newsImg = document.createElement('div');
            let newsTitle = document.createElement('h3');
            let newsDescription = document.createElement('p');
            let newsCta = document.createElement('a');
            let newsDate = document.createElement('div');
            let newsDateText;
            let newsDesc;

            newsImg.style.backgroundImage = 'url(' + feeds[i].thumbnail + ')';
            newsTitle.textContent = feeds[i].title;
            newsCta.textContent = 'Read Article';
            newsCta.href = feeds[i].link;
            newsBox.href = feeds[i].link;
            newsDateText = new Date(feeds[i].pubDate);
            newsDate.textContent =  newsDateText.getDate() + "." + String(newsDateText.getMonth() + 1).padStart(2, "0") + "." + newsDateText.getFullYear();

            newsDesc = feeds[i].description;
            newsDescription.textContent = newsDesc;
            console.log('FEEDS ', newsDesc);

    
            // if(newsTitle.textContent.length > 55) {
            //     newsTitle.textContent = feeds[i].title.substring(0, 55) + "...";
            // } else {
            //     newsTitle.textContent = feeds[i].title;
            // }

            
            
            // console.log(newsDescription);
            // if(!(newsDesc.includes('<p>'))) {
            //     newsDescription.textContent = newsDesc.substring(0, 90) + "...";
            // }
            // else if(newsDesc.includes('coindoo')) {
                
            //     let from;
            //     let to;

            //     from = newsDesc.indexOf('<p>') + 3;
            //     to = newsDesc.indexOf('[&#8230;]') - 8;

            //     // console.log("FROM  - >>>>",from);
            //     // console.log("TOO  - >>>>",to);

            //     newsDesc = newsDesc.slice(from, to);
            //     console.log("NEW VERSION ", newsDesc);
            //     newsDescription.textContent = newsDesc.substring(0, 90) + "...";

            // } else {
            //     let from;
            //     let to;

            //     from = newsDesc.indexOf('</p><p>') + 7;
            //     to = newsDesc.length - 4;

            //     console.log("FROM  - >>>>",from);
            //     console.log("TOO  - >>>>",to);

            //     newsDesc = newsDesc.slice(from, to);
            //     console.log("NEW VERSION ", newsDesc);
            //     newsDescription.textContent = newsDesc.substring(0, 90) + "...";
            // }

            newsBox.append(newsImg);
            newsBox.appendChild(newsTitle);
            newsBox.append(newsDescription);
            newsBox.append(newsCta);
            newsBox.append(newsDate);
            newsDate.classList.add('news-date');
            newsBox.classList.add('news-box');
            newsImg.classList.add('news-img');
            newsDescription.classList.add('news-description');
            newsTitle.classList.add('news-title');
            newsCta.classList.add('news-cta');
            newsCta.setAttribute("target", "_blank");
            newsBox.setAttribute("target", "_blank");

            newsWrapper.appendChild(newsBox);
            page += 1;
            
        }
        

    }

    generateFeed(page);



    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
            scrollIntoView();
        }else if (e.target.classList.contains('load-more')) {
            generateFeed(++page);
            console.log(page);
            if(page === 10) {
                document.querySelector('.load-more').style.display = 'none';
            }
        }
        else if (
            e.target.classList.contains('read-more-1') ||
            e.target.classList.contains('collapse-1')
        ) {
            // Read more 1
            toggleReadMore(e.target, {
                parent: '.about-us-content',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                onCollapsed: (clicked, options) => {
                    setTimeout(() => {
                        clicked.classList.remove('open');
                        clicked.classList.add('close');

                        const btnExpand = clicked
                            .closest(options.parent)
                            .querySelector('.read-more-1');
                        btnExpand.classList.remove('close');
                    }, 450);
                },
                onExpanded: (clicked, options) => {
                    clicked.classList.remove('open');
                    clicked.classList.add('close');
                }
            });
        } else if (
            e.target.classList.contains('faq-btn') ||
            e.target.classList.contains('faq-title')
        ) {
            toggleFaq(e.target, {
                collapseOthers: true
            });
        }
    });
}

function scrollTo(e) {
    e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }
}

function toggleMobileMenu(state) {
    const hasState = typeof state !== 'undefined';
    const header = document.querySelector('.site-header');
    const condition = hasState ? !!state : !header.classList.contains('nav-visible');

    condition ? header.classList.add('nav-visible') : header.classList.remove('nav-visible');
}
function toggleFaq(faqBtn, options = {}) {
    const faq = faqBtn.closest('.faq');
    const faqs = Array.from(faq.parentElement.children);
    const isLast = faqs.indexOf(faq) === faqs.length - 1;

    if (faq.classList.contains('collapsed')) {
        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }

    if (isLast) {
        // faq.scrollIntoView();
        scrollToElement(faq, 'auto');
    }
}

function toggleReadMore(clicked, options) {
    const toggleArea = clicked.closest(options.parent).querySelector(options.area);

    if (toggleArea.clientHeight) {
        toggleArea.style.height = 0;
        if (options.text) {
            const text = options.textCollapsed || 'Read More';
            options.text === 'self'
                ? (clicked.textContent = text)
                : (clicked.querySelector(options.text).textContent = text);
        }
        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(0deg)';

        if (typeof options.onCollapsed === 'function') {
            options.onCollapsed.call(null, clicked, options);
        }
    } else {
        const wrapper = toggleArea.querySelector(options.wrapper || 'div');

        // Handle any nested read mores.
        // This will trigger when device is mobile and there is nested desktop read more.
        if (Array.isArray(options.nested)) {
            options.nested.forEach(selector => {
                const nestedAreas = wrapper.querySelectorAll(selector);
                if (nestedAreas.length) {
                    nestedAreas.forEach(nestedArea => (nestedArea.style.height = 'auto'));
                }
            });
        }

        toggleArea.style.height = 'auto';
        toggleArea.style.paddingTop = '15px';

        if (options.text) {
            const text = options.textExpanded || 'Collapse';
            options.text === 'self'
                ? (clicked.textContent = text)
                : (clicked.querySelector(options.text).textContent = text);
        }

        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(180deg)';

        if (typeof options.onExpanded === 'function') {
            options.onExpanded.call(null, clicked, options);
        }
    }
}
